<template>
  <div>
    <el-input v-model="input" placeholder="请输入内容"></el-input>
    <el-radio v-model="radio" label="open">开启</el-radio>
    <el-radio v-model="radio" label="close">关闭</el-radio>
    <div v-for="item in ossress" :key="item.url">{{item.url}}</div>
    <el-upload
      class="upload-demo"
      action="#"
      :http-request="upload_attachment_handler"
      :show-file-list="false"
    >
      <el-button size="small" type="primary"
        >{{ $t("btn.importBtn") }}
      </el-button>
    </el-upload>
  </div>
</template>

<script>
import { ossAuthorization } from "@/utils/oss";
import { ulid } from "ulid";
export default {
  data() {
    return {
      input: "guide",
      radio: "close",
      ossress:[]
    };
  },

  mounted() {},
  methods: {
    async upload_attachment_handler(params) {
      //上传excel
      const self = this;
      let file = params.file;
      this.ossress=[]
      await ossAuthorization.call(self);
      self.client
        .put(`${self.input}/${self.radio=='open'?ulid():''}${file.name}`, file)
        .then((ossres) => {
          console.log(ossres);
          this.ossress.push({
            name:ossres.name,
            url:ossres.url
          })
        });
    },
  },
};
</script>
